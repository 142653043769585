(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  "mobile": {
    "landscape": 812,
    "portrait": 700,
    "small": 480
  },
  "tablet": {
    "landscape": 1024,
    "portrait": 768
  },
  "notebook": {
    "small": 1280,
    "normal": 1440
  }
};

},{}],2:[function(require,module,exports){
'use strict';

require('./modules/modals.js');

require('./modules/forms.js');

require('./modules/masks.js');

require('./modules/spinner.js');

require('./modules/scroll.js');

require('./modules/menus.js');

require('./modules/tabs.js');

require('./modules/galleries.js');

require('./modules/sliders.js');

require('./modules/spoilers.js');

require('./modules/filter.js');

require('./modules/messengers.js');

require('./modules/history.js');

require('./modules/order.js');

require('./modules/card-choose-offer.js');

},{"./modules/card-choose-offer.js":3,"./modules/filter.js":4,"./modules/forms.js":5,"./modules/galleries.js":6,"./modules/history.js":9,"./modules/masks.js":10,"./modules/menus.js":11,"./modules/messengers.js":12,"./modules/modals.js":13,"./modules/order.js":14,"./modules/scroll.js":15,"./modules/sliders.js":16,"./modules/spinner.js":17,"./modules/spoilers.js":18,"./modules/tabs.js":19}],3:[function(require,module,exports){
'use strict';

var _PriceComponent = require('./vue-components/PriceComponent.js');

var _PriceComponent2 = _interopRequireDefault(_PriceComponent);

var _OffersListComponent = require('./vue-components/OffersListComponent.js');

var _OffersListComponent2 = _interopRequireDefault(_OffersListComponent);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/**
 * Подключение в common.js
 *   import './modules/card-choose-offer.js';
 *
 * Пример подключения в шаблоне
 * <div id="js-card-price-app" class="product-card__controls-column" data-default-offer='<?=$model->getBasketObjectJson()?>' data-target-button=".js-cart-basket-button">

 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="{'is-checked': isActive(offer.id)}" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="$root.offer = offer">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 *
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 *
 * </div>
 *
 */
window.initPriceApp = function () {
  if (!$('#js-card-price-app').length) return;

  var cardPriceApp = new Vue({
    el: '#js-card-price-app',
    components: {
      'price-component': _PriceComponent2.default,
      'offers-list-component': _OffersListComponent2.default
    },
    data: {
      offer: {},
      targetButton: ''
    },
    mounted: function mounted() {
      this.$nextTick(function () {
        this.offer = JSON.parse(this.$el.getAttribute('data-default-offer'));
        this.targetButton = this.$el.getAttribute('data-target-button');
      });
    },
    watch: {
      //Обнавляем данные на кнопке купить
      offer: function offer(val, oldVal) {
        var data = $(this.targetButton).data();
        $.extend(data, val.basketData);
        $(this.targetButton).data(data);
      }
    }
  });
};

window.initPriceApp();

},{"./vue-components/OffersListComponent.js":20,"./vue-components/PriceComponent.js":21}],4:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers');

var opened_class = 'filter-is-opened';
var STORAGE_KEY = 'EXPANDED_FILTER_GROUPS';

$(document).on('click', '.js-toggle-mobile-filters', function (e) {
  e.preventDefault();

  console.log(_globals.$BODY.hasClass(opened_class));

  _globals.$BODY.toggleClass(opened_class);

  e.stopPropagation();

  if (_globals.$BODY.hasClass(opened_class)) {
    _globals.$BODY.on('click.close-filter', function (e) {
      var $target = $(e.target);

      if ($target.closest('#filter-aside').length) {
        return true;
      }

      _globals.$BODY.removeClass(opened_class).off('click.close-filter');
    });
  } else {
    _globals.$BODY.removeClass(opened_class).off('click.close-filter');
  }
});

$(document).on('click', '.js-toggle-filter-list', function (e) {
  e.preventDefault();

  $(this).parent().find('ul').toggleClass('is-expanded');

  saveExpandedGroups();
});

function saveExpandedGroups() {
  var expandedGroups = [];

  $('.filter__group-body ul.is-expanded').each(function () {
    expandedGroups.push($(this).closest('.filter__group').data('group-id'));
  });

  localStorage.setItem(STORAGE_KEY, JSON.stringify(expandedGroups));
}

// $(document).on('click', '.pager--bottom a', function() {
//   scrollToCatalog = true;
// });

$(document).on('yiiListViewUpdated', function () {
  // if (scrollToCatalog === true) {
  // $(window).scrollTo($('#product_list'), {duration: 200});
  if ($(window).width > 812) {
    $(window).scrollTo(0, { duration: 200 });
  }
  // scrollToCatalog = false;
  // }
});

$(document).on('groupsInit yiiListViewUpdated', function () {
  initGroupsState();
}).trigger('groupsInit');

function initGroupsState() {
  var expandedGroups = JSON.parse(localStorage.getItem(STORAGE_KEY)) || [];

  expandedGroups.forEach(function (item) {
    $('.filter__group[data-group-id="' + item + '"] .filter__group-body ul').addClass('is-expanded');
  });
}

$(document).on('click', '.js-filter-submit', function (e) {
  if ((0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) {
    e.preventDefault();
  }

  _globals.$BODY.removeClass(opened_class);
});

},{"./globals.js":7,"./helpers":8}],5:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Selectric
// ---------

_globals.$DOCUMENT.on('initSelectric yiiListViewUpdated', function () {
  $('select').selectric({
    disableOnMobile: false,
    nativeOnMobile: true
  });
}).trigger('initSelectric');

// Checkboxes
// ----------

_globals.$BODY.on('change initCheckboxes', '.js-checkbox input', function (event) {
  var $inp = $(event.target);
  var $label = $inp.closest('.js-checkbox');

  if ($inp.prop('checked')) {
    $label.addClass('is-checked');
  } else {
    $label.removeClass('is-checked');
  }
});
$('.js-checkbox input').trigger('initCheckboxes');

// Radio buttons
// -------------

_globals.$BODY.on('change initRadio', '.js-radio input', function (event) {
  var $inp = $(event.target);
  var $group = $('[name="' + $inp.attr('name') + '"]');
  var $labels = $group.closest('.js-radio');
  var $selected_item = $labels.find('input').filter(':checked').closest('.js-radio');

  $labels.removeClass('is-checked');
  $selected_item.addClass('is-checked');
});
$('.js-radio input').trigger('initRadio');

// Textarea autosize
// -----------------

$(function () {
  autosize($('textarea'));
});

// Fileinputs
// ----------

_globals.$BODY.on('change', '.js-fileinput input', function (event) {
  console.log(event);
  $(event.target).closest('.js-fileinput').parent().find('.js-fileinput-filename').text($(this).val());
});

},{"./globals.js":7}],6:[function(require,module,exports){
'use strict';

var scrollToCatalog = false;

$(document).on('click', 'div.pager .pager a', function () {
  scrollToCatalog = true;
});

$(document).on('yiiListViewUpdated lazyPicsInit', function () {
  if (scrollToCatalog === true) {
    $(window).scrollTo($('#product_list'), { duration: 200 });
    scrollToCatalog = false;
  }
  $('.js-lazy-pic').unveil(200);
}).trigger('lazyPicsInit');

$('body').on('iasRender', function () {
  $('.js-lazy-pic').unveil(200);
});

},{}],7:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TOSRUS_DEFAULTS = exports.$BODY = exports.$HTML = exports.$DOCUMENT = exports.$WINDOW = exports.HEADER_HEIGHT = exports.NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = exports.TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = exports.MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = exports.IS_TOUCH_DEVICE = exports.IS_IE = exports.IS_IOS = exports.IS_DESKTOP = exports.IS_MOBILE = undefined;

var _mediaQueriesConfigJson = require('../../media-queries-config.json.js');

var _mediaQueriesConfigJson2 = _interopRequireDefault(_mediaQueriesConfigJson);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

// Const
// -----

var IS_MOBILE = exports.IS_MOBILE = navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null;
var IS_DESKTOP = exports.IS_DESKTOP = !IS_MOBILE;
var IS_IOS = exports.IS_IOS = navigator.userAgent.match(/(iPad|iPhone|iPod)/g) ? true : false;
var IS_IE = exports.IS_IE = navigator.appVersion.indexOf("MSIE") !== -1 || navigator.userAgent.match(/Trident.*rv[ :]*11\./);
var IS_TOUCH_DEVICE = exports.IS_TOUCH_DEVICE = 'ontouchstart' in document;

var HTML = document.documentElement;

HTML.classList.remove('no-js');

if (IS_MOBILE) HTML.classList.add('is-mobile');
if (IS_DESKTOP) HTML.classList.add('is-desktop');
if (IS_IOS) HTML.classList.add('is-ios');
if (IS_IE) HTML.classList.add('is-ie');
if (IS_TOUCH_DEVICE) HTML.classList.add('is-touch-device');

var SMALL_MOBILE_WIDTH = exports.SMALL_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.small;
var MOBILE_WIDTH = exports.MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.portrait;
var LANDSCAPE_MOBILE_WIDTH = exports.LANDSCAPE_MOBILE_WIDTH = _mediaQueriesConfigJson2.default.mobile.landscape;
var PORTRAIT_TABLET_WIDTH = exports.PORTRAIT_TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.portrait;
var TABLET_WIDTH = exports.TABLET_WIDTH = _mediaQueriesConfigJson2.default.tablet.landscape;
var SMALL_NOTEBOOK_WIDTH = exports.SMALL_NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.small;
var NOTEBOOK_WIDTH = exports.NOTEBOOK_WIDTH = _mediaQueriesConfigJson2.default.notebook.normal;

var HEADER_HEIGHT = exports.HEADER_HEIGHT = $('.header').height();

// Selectors
// ---------

var $WINDOW = exports.$WINDOW = $(window);
var $DOCUMENT = exports.$DOCUMENT = $(document);
var $HTML = exports.$HTML = $(document.documentElement);
var $BODY = exports.$BODY = $(document.body);

// Tosrus default settings
// -----------------------

var TOSRUS_DEFAULTS = exports.TOSRUS_DEFAULTS = {
  buttons: {
    next: true,
    prev: true
  },
  keys: {
    prev: 37,
    next: 39,
    close: 27
  },
  wrapper: {
    onClick: 'close'
  }
};

// Trigger fonts loaded event
// --------------------------

if (document.fonts != undefined) {
  document.fonts.onloadingdone = function () {
    $(document).trigger('fontsLoaded');
  };
}

// Custom vh for mobile devices
// ----------------------------

$(function () {
  $(window).on('resize initVh', function () {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');
  }).trigger('initVh');
});

},{"../../media-queries-config.json.js":1}],8:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IS_SMALL_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = exports.IS_TABLET_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = exports.IS_DESKTOP_WIDTH = exports.WINDOW_HEIGHT = exports.WINDOW_WIDTH = undefined;
exports.status = status;

var _globals = require('./globals.js');

var WINDOW_WIDTH = exports.WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
var WINDOW_HEIGHT = exports.WINDOW_HEIGHT = _globals.$WINDOW.height();
_globals.$WINDOW.resize(function () {
  exports.WINDOW_WIDTH = WINDOW_WIDTH = window.innerWidth || _globals.$WINDOW.width();
  exports.WINDOW_HEIGHT = WINDOW_HEIGHT = _globals.$WINDOW.height();
});

var IS_DESKTOP_WIDTH = exports.IS_DESKTOP_WIDTH = function IS_DESKTOP_WIDTH() {
  return WINDOW_WIDTH > _globals.NOTEBOOK_WIDTH;
};
var IS_NOTEBOOK_WIDTH = exports.IS_NOTEBOOK_WIDTH = function IS_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.SMALL_NOTEBOOK_WIDTH && WINDOW_WIDTH <= _globals.NOTEBOOK_WIDTH;
};
var IS_SMALL_NOTEBOOK_WIDTH = exports.IS_SMALL_NOTEBOOK_WIDTH = function IS_SMALL_NOTEBOOK_WIDTH() {
  return WINDOW_WIDTH > _globals.TABLET_WIDTH && WINDOW_WIDTH <= _globals.SMALL_NOTEBOOK_WIDTH;
};
var IS_TABLET_WIDTH = exports.IS_TABLET_WIDTH = function IS_TABLET_WIDTH() {
  return WINDOW_WIDTH >= _globals.PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH <= _globals.TABLET_WIDTH;
};
var IS_MOBILE_WIDTH = exports.IS_MOBILE_WIDTH = function IS_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.MOBILE_WIDTH;
};
var IS_LANDSCAPE_MOBILE_WIDTH = exports.IS_LANDSCAPE_MOBILE_WIDTH = function IS_LANDSCAPE_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.LANDSCAPE_MOBILE_WIDTH;
};
var IS_SMALL_MOBILE_WIDTH = exports.IS_SMALL_MOBILE_WIDTH = function IS_SMALL_MOBILE_WIDTH() {
  return WINDOW_WIDTH <= _globals.SMALL_MOBILE_WIDTH;
};

// Response status
function status(response) {
  if (!response.ok) {
    throw new Error(response.statusText);
  }
  return response;
}

},{"./globals.js":7}],9:[function(require,module,exports){
'use strict';

var $backLink = $('.js-history-back');

if ($backLink.length) {
  if (document.referrer == '') {
    $backLink.css('visibility', 'hidden');
  } else {
    $backLink.click(function () {
      window.history.back();
    });
  }
}

},{}],10:[function(require,module,exports){
'use strict';

(function () {

  $('input[type="tel"]').each(function () {

    var $input = $(this);
    var defaultPlaceholder = $input.attr('placeholder');
    var defaultPlaceholderMask = '+7 (___) ___-__-__';
    var phoneMask = '+7 (000) 000-00-00';
    var pasted = false;

    $input.on('focus', function () {
      $input.attr('placeholder', defaultPlaceholderMask);
    }).on('blur', function () {
      $input.attr('placeholder', defaultPlaceholder);
    });

    $input.on('paste drop', function () {
      var isEmpty = !$input.val();
      var element = $input.get(0);
      var selectionLength = element.selectionEnd - element.selectionStart;

      if (!isEmpty && $input.val().length !== selectionLength) {
        return;
      }

      pasted = true;
    });

    $input.on('input', function (e) {
      var value = $input.val();

      if (value.startsWith('8')) {
        if (pasted && value.length !== 11) {
          pasted = false;
          return;
        }

        pasted = false;

        e.stopPropagation();

        value = value.replace('8', '+7');
        $input.val(value);
      }
    });

    $input.mask(phoneMask);
  });
})();

},{}],11:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var $menus = $('.js-menu');

if (_globals.IS_DESKTOP) {
  $menus.on('mouseenter.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('unhoverTimeout'));

    if ($this.hasClass('has-dropdown')) {
      $this.data('hoverTimeout', setTimeout(function () {
        $this.addClass('is-hovered');
      }, 200));
    } else {
      $this.addClass('is-hovered');
    }
  });

  $menus.on('mouseleave.js-menu', 'li', function () {
    var $this = $(this);

    clearTimeout($this.data('hoverTimeout'));

    $this.data('unhoverTimeout', setTimeout(function () {
      $this.removeClass('is-hovered');
    }, 200));
  });
}

if (_globals.IS_MOBILE) {
  $menus.on('click.js-m-menu', 'a', function (e) {
    e.preventDefault();

    var $anchor = $(this);
    var $parent = $anchor.parent();
    var has_dropdown = $parent.hasClass('has-dropdown') || $parent.hasClass('has-mobile-dropdown');
    var is_hovered = $parent.hasClass('is-hovered');
    var link = $anchor.attr('href');

    $parent.siblings().removeClass('is-hovered');

    if (!has_dropdown) {
      location.href = $anchor.attr('href');
    } else {
      if (is_hovered && link) {
        location.href = link;
      } else {
        $parent.toggleClass('is-hovered');
      }
    }
  });
}

$('.js-toggle-mobile-menu').click(function (e) {
  e.preventDefault();

  var opened_class = 'menu-is-opened';

  _globals.$BODY.toggleClass(opened_class);

  if (_globals.$BODY.hasClass(opened_class)) {
    _globals.$BODY.on('click.close-menu', function (e) {
      var $target = $(e.target);

      if ($target.closest('.mobile-menu').length || $target.hasClass('js-toggle-mobile-menu')) {
        e.stopPropagation();
        return false;
      }

      _globals.$BODY.removeClass(opened_class).off('click.close-menu');
    });
  } else {
    _globals.$BODY.removeClass(opened_class).off('click.close-menu');
  }
});

},{"./globals.js":7}],12:[function(require,module,exports){
'use strict';

$('.js-toggle-messengers').click(function (e) {
  e.preventDefault();

  $(this).parent().toggleClass('is-active');
});

},{}],13:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

// Open popup
_globals.$DOCUMENT.on('click.overlay-open', '.js-overlay', function (e) {
  e.preventDefault();

  var $popup = $(this).attr('href');

  $.overlayLoader(true, {
    node: $popup,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });
});

_globals.$DOCUMENT.on('click.overlay-open', '.js-ajax-overlay', function (e) {
  e.preventDefault();

  var $self = $(this);
  var $node = $($self.attr('href'));

  $.overlayLoader(true, {
    node: $node,
    hideSelector: '.js-close-popup',
    fixedElements: '.js-fixed-element'
  });

  $.ajax({
    type: $self.data('type'),
    url: $self.data('ajax-url'),
    data: _parseAjaxOverlayParams($self.data('ajax-params')),
    timeout: 10000,
    success: function success(data) {
      $node.find('.js-ajax-data').html(data);
      // window.initPriceApp('#js-card-price-app-details');
      // $(document).trigger('ajaxBlockLoaded');
    },
    error: function error(data) {}
  });
});

function _parseAjaxOverlayParams(str) {

  if (str == undefined || str == '') return {};

  var obj = {};
  var array = str.split('&');

  array.forEach(function (value) {
    var _a = value.split('=');
    obj[_a[0]] = _a[1];
  });

  return obj;
}

// Autofocus
_globals.$DOCUMENT.on('overlayLoaderShow', function (e, $node) {
  $node.find('.js-autofocus-inp').focus();
});

},{"./globals.js":7}],14:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

var $order_aside = $('.js-order-fixed-panel');

if ($order_aside.length && !(0, _helpers.IS_MOBILE_WIDTH)()) {
  var PANEL_OFFSET_TOP = 25;
  var PANEL_OFFSET_BOTTOM = 0;
  var $container = $('.js-order-page-layout');
  var aside_height = $order_aside.outerHeight() + PANEL_OFFSET_BOTTOM;
  var aside_position = $order_aside.offset().top;
  var bottom_limit = $container.offset().top + $container.height();
  var max_offset = bottom_limit - aside_height;

  _globals.$WINDOW.on('scroll.order-page-scroll', function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    var offset = scroll_top - aside_position + PANEL_OFFSET_TOP;

    if (offset < 0) {
      $order_aside.css('top', 0);
    } else if (offset >= 0) {
      if (scroll_top < max_offset - PANEL_OFFSET_TOP) {
        $order_aside.css('top', offset);
      } else {
        $order_aside.css('top', max_offset - aside_position);
      }
    }
  });
}

},{"./globals.js":7,"./helpers.js":8}],15:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

var _helpers = require('./helpers.js');

// Scroll to
// ---------

_globals.$DOCUMENT.on('click.scroll-to', '.js-scroll-to', function (e) {
  e.preventDefault();

  var $lnk = $(this);
  var $elem_to_scroll = $($lnk.attr('href'));
  var speed = $lnk.data('speed') || 150;
  var offset = $lnk.data('offset') || 0;

  _globals.$WINDOW.scrollTo($elem_to_scroll, { duration: speed, offset: offset });
});

// Scrolling to top
// ----------------

if (!(0, _helpers.IS_MOBILE_WIDTH)()) {
  var $go_top_btn = $('<div class="go-top-btn"></div>');

  $go_top_btn.click(function () {
    _globals.$WINDOW.scrollTo(0, 200);
  });

  _globals.$WINDOW.scroll(function () {
    var scroll_top = _globals.$WINDOW.scrollTop();
    if (scroll_top > 0) {
      $go_top_btn.addClass('is-visible');
    } else {
      $go_top_btn.removeClass('is-visible');
    }
  });

  _globals.$BODY.append($go_top_btn);
}

(function (document, history, location) {
  var HISTORY_SUPPORT = !!(history && history.pushState);

  var anchorScrolls = {
    ANCHOR_REGEX: /^#[^ ]+$/,
    OFFSET_HEIGHT_PX: $('.header').height(),

    /**
     * Establish events, and fix initial scroll position if a hash is provided.
     */
    init: function init() {
      this.scrollToCurrent();
      window.addEventListener('hashchange', this.scrollToCurrent.bind(this));
      document.body.addEventListener('click', this.delegateAnchors.bind(this));
    },

    /**
     * Return the offset amount to deduct from the normal scroll position.
     * Modify as appropriate to allow for dynamic calculations
     */
    getFixedOffset: function getFixedOffset() {
      return this.OFFSET_HEIGHT_PX;
    },

    /**
     * If the provided href is an anchor which resolves to an element on the
     * page, scroll to it.
     * @param  {String} href
     * @return {Boolean} - Was the href an anchor.
     */
    scrollIfAnchor: function scrollIfAnchor(href, pushToHistory) {
      var match, rect, anchorOffset;

      if (!this.ANCHOR_REGEX.test(href)) {
        return false;
      }

      match = document.getElementById(href.slice(1));

      if (match) {
        rect = match.getBoundingClientRect();
        anchorOffset = window.pageYOffset + rect.top - this.getFixedOffset();
        window.scrollTo(window.pageXOffset, anchorOffset);

        // Add the state to history as-per normal anchor links
        if (HISTORY_SUPPORT && pushToHistory) {
          history.pushState({}, document.title, location.pathname + href);
        }
      }

      return !!match;
    },

    /**
     * Attempt to scroll to the current location's hash.
     */
    scrollToCurrent: function scrollToCurrent() {
      this.scrollIfAnchor(window.location.hash);
    },

    /**
     * If the click event's target was an anchor, fix the scroll position.
     */
    delegateAnchors: function delegateAnchors(e) {
      var elem = e.target;

      if (elem.nodeName === 'A' && !$(elem).hasClass('js-overlay') && this.scrollIfAnchor(elem.getAttribute('href'), true)) {
        e.preventDefault();
      }
    }
  };

  window.addEventListener('DOMContentLoaded', anchorScrolls.init.bind(anchorScrolls));
})(window.document, window.history, window.location);

},{"./globals.js":7,"./helpers.js":8}],16:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

$(function () {
  $('.js-slideshow').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      // autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      arrows: true,
      pauseOnHover: false
    });

    setTimeout(function () {
      $this.slick('slickPlay');
    }, 3000);
  });
});

$(window).on('sliders-init resize', function () {

  $('.js-vitrine-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    if ($(window).width() <= _globals.TABLET_WIDTH) {
      if (!$this.hasClass('slick-initialized')) return;

      $this.slick('unslick');
    } else {
      if ($this.hasClass('slick-initialized')) {
        if (!$this.hasClass('js-mobile-vitrine-slider')) {
          return;
        } else {
          $this.slick('unslick');
        }
      }

      $this.slick({
        slidesToShow: 6,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        appendArrows: $parent,
        responsive: [{
          breakpoint: 1701,
          settings: {
            slidesToShow: 5
          }
        }, {
          breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: 4
          }
        }]
      });
    }
  });

  $('.js-mobile-vitrine-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    if ($(window).width() <= _globals.SMALL_MOBILE_WIDTH) {
      if ($this.hasClass('slick-initialized')) {
        if ($this.hasClass('js-vitrine-slider')) {
          $this.slick('unslick');
        } else {
          return;
        }
      }

      $this.slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        dots: true
      });
    } else {
      if (!$this.hasClass('slick-initialized')) return;

      if (!$this.hasClass('js-vitrine-slider')) {
        $this.slick('unslick');
      }
    }
  });

  $('.js-features-slider').each(function () {
    var $this = $(this);

    if ($(window).width() > _globals.PORTRAIT_TABLET_WIDTH) {
      if (!$this.hasClass('slick-initialized')) return;

      $this.slick('unslick');
    } else {
      if ($this.hasClass('slick-initialized')) return;

      $this.slick({
        slidesToShow: 2,
        slidesToScroll: 2,
        infinite: false,
        arrows: false,
        dots: true,
        responsive: [{
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }]
      });
    }
  });

  $('.js-gallery-slider').each(function () {
    var $this = $(this);
    var $parent = $this.parent();

    if ($(window).width() <= _globals.TABLET_WIDTH && $(window).width() > _globals.SMALL_MOBILE_WIDTH) {
      if (!$this.hasClass('slick-initialized')) return;

      $this.slick('unslick');
    } else {
      if ($this.hasClass('slick-initialized')) return;

      $this.slick({
        slidesToShow: 5,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        appendArrows: $parent,
        responsive: [{
          breakpoint: _globals.SMALL_NOTEBOOK_WIDTH + 1,
          settings: {
            slidesToShow: 4
          }
        }, {
          breakpoint: _globals.SMALL_MOBILE_WIDTH + 1,
          settings: {
            slidesToShow: 1,
            arrows: false,
            dots: true
          }
        }]
      });
    }
  });
}).trigger('sliders-init');

$(function () {
  $('.js-product-gallery').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      asNavFor: '.js-product-thumbs',
      responsive: [{
        breakpoint: _globals.LANDSCAPE_MOBILE_WIDTH + 1,
        settings: {
          dots: true
        }
      }]
    });
  });

  $('.js-product-thumbs').each(function () {
    var $this = $(this);

    $this.slick({
      slidesToShow: 8,
      slidesToScroll: 1,
      infinite: false,
      arrows: false,
      asNavFor: '.js-product-gallery',
      focusOnSelect: true,
      responsive: [{
        breakpoint: _globals.NOTEBOOK_WIDTH + 1,
        settings: {
          slidesToShow: 7
        }
      }, {
        breakpoint: _globals.TABLET_WIDTH + 1,
        settings: {
          slidesToShow: 6
        }
      }]
    });
  });
});

},{"./globals.js":7}],17:[function(require,module,exports){
'use strict';

var _globals = require('./globals.js');

_globals.$DOCUMENT.on('mousedown.js-spinner', '.js-spinner-down, .js-spinner-up', function () {
  var $spinner_control = $(this);
  var $input = $spinner_control.siblings('.inp');
  var step = $input.data('step') ? $input.data('step') : 1;
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;
  var value = parseInt($input.val(), 10);
  var inc_timeout = void 0,
      inc_interval = void 0,
      dec_timeout = void 0,
      dec_interval = void 0;

  $spinner_control.on('mouseup.js-spinner', clearAll).on('mouseleave.js-spinner', $spinner_control, clearAll);

  if ($spinner_control.hasClass('js-spinner-down')) {
    decVal();dec_timeout = setTimeout(function () {
      dec_interval = setInterval(decVal, 70);
    }, 300);
  }

  if ($spinner_control.hasClass('js-spinner-up')) {
    incVal();inc_timeout = setTimeout(function () {
      inc_interval = setInterval(incVal, 70);
    }, 300);
  }

  function incVal() {
    if ($.isMouseLoaderActive()) return;

    value = value + step;
    $input.val(value).change();
  }

  function decVal() {
    if ($.isMouseLoaderActive()) return;

    if (may_be_zero) {
      if (value >= step) {
        value = value - step;
        $input.val(value).change();
      }
    } else {
      if (value > step) {
        value = value - step;
        $input.val(value).change();
      }
    }
  }

  function clearAll() {
    clearTimeout(dec_timeout);clearInterval(dec_interval);
    clearTimeout(inc_timeout);clearInterval(inc_interval);
  }
});

_globals.$DOCUMENT.on('keydown', '.js-spinner .inp', function (e) {
  var $input = $(this);

  if (e.keyCode == 46 || e.keyCode == 8 || e.keyCode == 9 || e.keyCode == 27 || e.keyCode == 65 && e.ctrlKey === true || e.keyCode >= 35 && e.keyCode <= 39) {
    return;
  } else {
    if ((e.keyCode < 48 || e.keyCode > 57) && (e.keyCode < 96 || e.keyCode > 105)) {
      e.preventDefault();
    }
  }
});

_globals.$DOCUMENT.on('keyup paste', '.js-spinner .inp', function (e) {
  var $input = $(this);
  var may_be_zero = $input.data('zero') ? $input.data('zero') : false;

  if ($input.val() == 0 || $input.val() == '') {
    if (!may_be_zero) {
      $input.val(1);
    } else {
      $input.val(0);
    }
  }
});

},{"./globals.js":7}],18:[function(require,module,exports){
'use strict';

var _helpers = require('./helpers');

$(function () {

  $('.js-spoiler').each(function () {
    var $this = $(this);
    $(window).on('spoilersInit resize', function () {
      var isExpanded = $this.hasClass('is-expanded');
      var isInsideHiddenTab = $this.closest('.tabs-panel:not(.is-active)').length;
      var isInsideCollapsedSpoiler = $this.closest('.js-spoiler:not(.is-expanded)').length;
      var $el = $this.find('.js-spoiler-body').first();
      var el = $el[0];
      $('.js-spoiler-body').each(function () {
        $(this)[0].style.setProperty('transition', 'none');
        $(this).find('ul').addClass('is-expanded-for-calc');
      });
      if (!isExpanded) $this.addClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').addClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').addClass('is-active');
      el.style.setProperty('--max-height', '');
      el.style.setProperty('--max-height', $el.outerHeight() + 'px');
      if (!isExpanded) $this.removeClass('is-expanded-for-calc');
      if (isInsideCollapsedSpoiler) $this.closest('.js-spoiler').removeClass('is-expanded');
      if (isInsideHiddenTab) $this.closest('.tabs-panel').removeClass('is-active');
      $('.js-spoiler-body').each(function () {
        $(this)[0].style.setProperty('transition', '');
        $(this).find('ul').removeClass('is-expanded-for-calc');
      });
    });
  });
  setTimeout(function () {
    $(window).trigger('spoilersInit');
  }, 0);

  $('.js-toggle-spoiler').click(function () {
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $('.js-toggle-mobile-spoiler').click(function () {
    if (!(0, _helpers.IS_LANDSCAPE_MOBILE_WIDTH)()) return;
    $(this).closest('.js-spoiler').toggleClass('is-expanded');
    setTimeout(function () {
      $(window).trigger('spoilerToggled');
    }, 250);
  });

  $(document).on('click', '.js-toggle-share-socials', function () {
    var $shareBlock = $(this).parent();
    $shareBlock.toggleClass('is-active');
    if ($shareBlock.hasClass('is-active')) {
      $('body').on('click.close-sharing', function (e) {
        var $target = $(e.target);
        if ($target.closest('.js-share-block').length) {
          e.stopPropagation();
          return false;
        }
        $shareBlock.removeClass('is-active');
        $('body').off('click.close-sharing');
      });
    } else {
      $shareBlock.removeClass('is-active');
      $('body').off('click.close-sharing');
    }
  });
});

},{"./helpers":8}],19:[function(require,module,exports){
'use strict';

$('.js-tabs .tabs-nav li a').click(function (e) {
  e.preventDefault();
  e.stopPropagation();

  var $this = $(this);
  var $panel = $($this.attr('href'));

  $this.closest('li').addClass('is-active').siblings().removeClass('is-active');
  $panel.closest('.tabs').find('.tabs-panel').removeClass('is-active').hide();
  $panel.fadeIn().addClass('is-active');
});

// $(function() {
//   $('.js-tabs').each(function() {
//     $(this).find('.tabs-nav li:first a').click();
//   });
// });

$(document).on('initTabs reinitTabs', function () {
  $('.js-tabs').each(function () {
    var $tabsNav = $(this).find('.tabs-nav').first();
    var $activeTab = $tabsNav.find('.is-active a');
    // if ($('.compare-page').length) {
    //   $activeTab = $(document).activeCompareTabIndex != 'undefined' ? $(this).find(`.tabs-nav li:eq(${$(document).activeCompareTabIndex}) a`) : null;
    // } else {
    var hash = document.location.hash.replace(/^#!/, '#');
    if (hash != '') {
      $activeTab = $('.js-tabs .tabs-nav a[href=\'' + hash + '\']');
      setTimeout(function () {
        $(window).scrollTo(0);
      }, 200);
    }
    // }
    if (!$activeTab.length) {
      $activeTab = $tabsNav.find('li:first a');
    }
    $activeTab.click();
  });
}).trigger('initTabs');

},{}],20:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пирмер подключения в шаблоне:
 * <?php $offers = $model->getOffersWithParameter(false) ?>
 * <offers-list-component :offers-list='<?=json_encode($offers)?>' inline-template>
 *  <div>
 *    <label class="item js-radio" v-bind:class="getClass(offer)" v-bind:data-size="offer.text"
 *           v-for="offer in offersList" @click="click(offer)">{{offer.text}}</label>
 *  </div>
 * </offers-list-component>
 */

/**
 * @type {{props: {selectedId: {type: NumberConstructor}, offersList: {type: ArrayConstructor}}, methods: {isActive(*): *}}}
 */
var OffersListComponent = {
  props: {
    selectedId: {
      type: Number
    },
    offersList: {
      type: Array
    },
    selectedClass: {
      type: String,
      default: 'is-checked'
    },
    disabledClass: {
      type: String,
      default: 'disabled'
    }
  },
  methods: {
    isActive: function isActive(id) {
      return this.$root.offer.id == id;
    },
    click: function click(offer) {
      if (offer.disabled) return;

      this.$root.offer = offer;
    },
    getClass: function getClass(offer) {
      var classArray = [];

      if (this.isActive(offer.id)) classArray.push(this.selectedClass);

      if (offer.disabled) classArray.push(this.disabledClass);

      return classArray.join(' ');
    }
  }
};

exports.default = OffersListComponent;

},{}],21:[function(require,module,exports){
'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
/**
 * Пример подключения в шаблоне
 * <price-component :price="this.offer.price" :old-price="this.offer.oldPrice" inline-template>
 * <div class="product-card__price-group">
 *   <div class="product-card__old-price" v-if="oldPrice">
 *     <span v-html="oldPriceFormatted"></span>
 *     <div class="product-card__discount" v-html="economyPercentFormatted"></div>
 *   </div>
 *   <div class="product-card__price" v-html="priceFormatted"><?php echo PriceHelper::price($model->getPrice(), ' <span class="currency"><span>₽</span></span>');?></div>
 * </div>
 * </price-component>
 */

/**
 * @type {{props: {price: {type: NumberConstructor}, oldPrice: {type: NumberConstructor, default: number}, currencySuffix: {type: StringConstructor, default: string}, emptyPriceText: {type: StringConstructor, default: string}}, computed: {economyPercent(): (number|*), priceFormatted(): *, oldPriceFormatted(): *, economyPercentFormatted(): string}}}
 */
var PriceComponent = {
  props: {
    price: {
      type: Number
    },
    oldPrice: {
      type: Number,
      default: 0
    },
    currencySuffix: {
      type: String,
      default: ' <span class="currency"><span>₽</span></span>'
    },
    emptyPriceText: {
      type: String,
      default: ' <span class="call">Спец. цена, звоните!</span>'
    }
  },
  computed: {
    economyPercent: function economyPercent() {
      if (!this.oldPrice) return 0;
      var percent = (this.price - this.oldPrice) * 100 / this.oldPrice;
      return Math.ceil(percent);
    },
    priceFormatted: function priceFormatted() {
      if (this.price > 0) return this.price.toLocaleString('ru-RU') + this.currencySuffix;

      return this.emptyPriceText;
    },
    oldPriceFormatted: function oldPriceFormatted() {
      if (this.oldPrice > 0) return this.oldPrice.toLocaleString('ru-RU') + this.currencySuffix;

      return this.emptyPriceText;
    },
    economyPercentFormatted: function economyPercentFormatted() {
      return this.economyPercent + '%';
    }
  }
};

exports.default = PriceComponent;

},{}]},{},[2]);
